import React, { useState, useEffect } from 'react';
import { Spinner, Row, Col } from 'react-bootstrap';
import './components.css'

export default function ImoveisLeilao() {
    const [data, setData] = useState([]);
    var len = 0

    useEffect(() => {
        fetch('https://leilao.gruposf.com.br/backend/imoveisleilao.php').then(response =>
            response.json()
        ).then(json => {
            setData(json)
        }).catch(function (err) {
            console.warn('Something went wrong.', err);
        });
    }, []);

    return (
        <>
            <div className='leiloes'>
                <img src='/logo.webp' alt="" height={"150px"} />
            </div>

            {data.length === 0 ?
                <Spinner animation="border" variant="secondary" /> :
                <>
                    <Row style={{ minWidth: '100%'}}>
                        {data.map(item => {
                            let tag = item.tag
                            if(tag.includes("Comercial")) {
                                return (
                                    <Col sm={12} md={6} lg={3} key={item.title} style={{ padding: '8px' }}>
                                        <div className='cards'>
                                        <a href={item.link}>
                                            <img src={item.image} alt='' width={'90%'} />
                                            <h5>{item.title}</h5>
                                        </a>
                                        <h5 style={{color: "#ff9532"}}>{item.price.replace(/Valor de venda: |Valor de venda à vista: /g, "")}</h5>
                                        </div>
                                        
                                    </Col>
                                )
                            }
                        })}
                    </Row>
                </>
            }
            <span className='spacer'></span>
        </>

    );
}
