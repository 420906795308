import React, { useState, useEffect } from 'react';
import { Spinner } from 'react-bootstrap';
import Mega from './Components/mega';
import LeilaoImovel from './Components/leilaoimovel';
import ImoveisLeilao from './Components/imoveisleilao';
import Zuk from './Components/zuk';
import './App.css';

function App() {

  return (
    <div className="App">
      <Mega />
      <LeilaoImovel />
      <ImoveisLeilao />
      <Zuk />
    </div>
  );
}

export default App;
