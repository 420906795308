import React, { useState, useEffect } from 'react';
import { Spinner, Row, Col } from 'react-bootstrap';
import './components.css'

export default function Mega() {
  const [mega, setMega] = useState([]);

  useEffect(() => {
    fetch('https://leilao.gruposf.com.br/backend/mega.php').then(response =>
      response.json()
    ).then(json => {
      setMega(json)
    }).catch(function (err) {
      console.warn('Something went wrong.', err);
    });
  }, []);

  return (
    <>
      <div className='leiloes'>
        <img src='/megaleiloes-logotipo.svg' alt="" height={"150px"} />
      </div>

      {Object.keys(mega).length === 0 ?
        <Spinner animation="border" variant="secondary" /> :
        <>

          {Object.keys(mega).map(tipo => {
            return (
              <>
                <Row style={{minWidth: "100%"}} >
                  {mega[tipo]?.map(item => {
                    return (
                      <Col sm={12} md={6} lg={3} key={item.title} style={{padding: "8px" }}>
                        <div className='cards'>
                          <a href={item.link}>
                            <img src={item.image} alt='' width={'90%'} />
                            <h5>{item.title}</h5>
                          </a>
                          <h5 style={{ color: item.status !== 'Em breve' ? '#2baf49' : '#f98621' }}>{item.status}</h5>
                          <h5>{item.price}</h5>
                        </div>
                      </Col>
                    )
                  })}
                </Row>
              </>
            )
          })}
        </>

      }
      <span className='spacer'></span>
    </>

  );
}
